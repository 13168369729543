import {
  BeijeDot01,
  BeijeDot03,
  BeijeDot06,
  BeijeDot09,
} from "@beije/react-icons";
import { Box, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useTranslation } from "next-i18next";
import { FC } from "react";

import { CTASingleSection } from "./CTASingleSection";

type Props = {
  variant?: 1 | 2;
};

export const CTADouble: FC<Props> = ({ variant }) => {
  const { t } = useTranslation();

  const theme = useTheme();
  const mediumBreakpoint = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: {
          xs: "column",
          sm: "column",
        },
        alignItems: {
          xs: "flex-start",
          sm: "flex-start",
        },
        padding: {
          xs: 2.5,
          sm: 2.5,
          md: 0,
          lg: 0,
        },
        gap: {
          xs: 3,
          sm: 3,
          md: 0,
          lg: 0,
        },
        backgroundColor: theme.palette.background.default,
        borderRadius: 1,
        width: "100%",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: {
            xs: "column",
            sm: "column",
            md: "row",
            lg: "row",
          },
          alignItems: {
            xs: "flex-start",
            sm: "flex-start",
          },
          gap: {
            xs: 3,
            sm: 3,
            md: 0,
            lg: 0,
          },
          width: "100%",
        }}
      >
        {variant === 1 && (
          <CTASingleSection
            backgroundColor={theme.palette.common.beije.secondary.green.main}
            title={t("cta:double.one.title")}
            description={t("cta:double.one.description")}
            button={{
              label: t("cta:double.one.button"),
              href: "/packets",
            }}
            color="secondary"
          >
            <BeijeDot01
              style={{
                position: "absolute",
                width: 279,
                height: 281,
                left: mediumBreakpoint ? 550 : 102,
                top: mediumBreakpoint ? 179 : 138,
                opacity: 0.1,
              }}
            />
            <BeijeDot06
              style={{
                position: "absolute",
                width: 210,
                height: 216,
                left: -57,
                top: -24,
                opacity: 0.1,
              }}
              fill="#000"
            />
          </CTASingleSection>
        )}

        {variant === 2 && (
          <CTASingleSection
            backgroundColor={theme.palette.common.beije.primary.darkOrange.main}
            title={t("cta:double.two.title")}
            description={t("cta:double.two.description")}
            button={{
              label: t("cta:double.two.button"),
              href: "/packets",
            }}
            color="secondary"
          >
            <BeijeDot01
              style={{
                position: "absolute",
                width: 279,
                height: 281,
                left: mediumBreakpoint ? 550 : 102,
                top: mediumBreakpoint ? 179 : 138,
                opacity: 0.1,
              }}
            />
            <BeijeDot06
              style={{
                position: "absolute",
                width: 210,
                height: 216,
                left: -57,
                top: -24,
                opacity: 0.1,
              }}
              fill="#000"
            />
          </CTASingleSection>
        )}

        <CTASingleSection
          backgroundColor={theme.palette.common.beije.primary.darkPurple.main}
          title={t("cta:double.two.title")}
          description={t("cta:double.two.description")}
          button={{
            label: t("cta:double.two.button"),
            href: "/custom-packet",
          }}
          color="secondary"
        >
          <BeijeDot09
            style={{
              position: "absolute",
              width: 188,
              height: 190,
              left: mediumBreakpoint ? 398 : 194,
              top: mediumBreakpoint ? 107 : 210,
              opacity: 0.1,
            }}
          />
          <BeijeDot03
            style={{
              position: "absolute",
              width: 145.21,
              height: 145.73,
              left: mediumBreakpoint ? 163 : -41,
              top: mediumBreakpoint ? -59 : 44,
              opacity: 0.1,
            }}
          />
          <BeijeDot01
            style={{
              position: "absolute",
              width: 279,
              height: 281,
              left: mediumBreakpoint ? 135 : -69,
              top: mediumBreakpoint ? 202 : 305,
              opacity: 0.1,
            }}
          />
        </CTASingleSection>
      </Box>
    </Box>
  );
};
