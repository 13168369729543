import { SVGProps, memo } from "react";

const SvgBeijeDot03 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    viewBox="0 0 146 147"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M145.42 71.684c0 13.02-11.39 22.5-17.72 33-6.45 10.69-11.81 21.18-22.85 27.34-10.62 5.93-22.25 14.09-35.31 14.09-13.06 0-25.97-5.36-36.4-11.4-10.74-6.22-21.31-15.39-27.53-26.13C-.43 98.164.75 84.564.75 71.674c0-12.89-2.56-27.35 3.47-37.9 6.2-10.84 22.04-12.75 32.74-19.11C47.41 8.454 56.61.384 69.54.384s22.8 7.64 33.45 13.74c11 6.3 21.67 11.49 28.09 22.29 6.32 10.62 14.34 22.24 14.34 35.26v.01Z"
      fill={props.fill || "#fff"}
    />
  </svg>
);

const Memo = memo(SvgBeijeDot03);
export default Memo;
