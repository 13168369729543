import { SVGProps, memo } from "react";

const SvgBeijeDot06 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    viewBox="0 0 142 146"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M141.118 75.445c0 19.42-14.83 33.739-27.55 46.459-12.72 12.72-27.04 23.27-46.46 23.27s-32.36-11.92-45.09-24.65C9.298 107.804.558 94.854.558 75.434s-1.4-42.5 11.32-55.23C24.598 7.484 47.688.564 67.108.564c19.42 0 36.88 12.54 49.6 25.26 12.72 12.72 24.41 30.19 24.41 49.6v.02Z"
      fill={props.fill || "#fff"}
    />
  </svg>
);

const Memo = memo(SvgBeijeDot06);
export default Memo;
