import { SVGProps, memo } from "react";

const SvgBeijeDot09 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    viewBox="0 0 111 112"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M7.462 76.069c-10.2-12.68-6.15-28.09-2.8-41.49 2.92-11.71 12.26-20.99 24.56-28 12.3-7.01 19.95-6.93 33.95-4.87 16.02 2.36 23.41 13.37 33.61 26.05 10.2 12.68 16.3 16.5 12.96 29.9-2.92 11.71-3.07 22.9-15.37 29.91-12.3 7.01-22.27 25.73-36.27 23.67-16.02-2.36-40.44-22.48-50.64-35.16v-.01Z"
      fill={props.fill || "#fff"}
    />
  </svg>
);

const Memo = memo(SvgBeijeDot09);
export default Memo;
