import { Box, Typography, Button } from "@mui/material";
import { Theme } from "@mui/material/styles";
import Link from "next/link";

import SampleImage from "public/images/cta-sample.jpg";
import CTATrialPackImage from "public/images/cta-trial-pack.png";
import { useTranslation } from "react-i18next";

import { TRIAL_PRODUCT_PAGE_LINK } from "src/constant";

export const CTATrialPackage = () => {
  const { t } = useTranslation();

  const containerStyle = (theme: Theme) => ({
    width: "100%",
    [theme.breakpoints.down("md")]: {
      paddingBottom: 5,
    },
  });

  const contentStyle = (theme: Theme) => ({
    display: "flex",
    color: "#FFFFFF",
    "& > div": {
      width: "50%",
      minHeight: 300,
    },
    [theme.breakpoints.down("md")]: {
      alignItems: "center",
      flexDirection: "column",
      textAlign: "center",
      "& > div": {
        width: "80%",
      },
    },
  });

  const leftContentStyle = (theme: Theme) => ({
    backgroundImage: `url(${CTATrialPackImage?.src || SampleImage})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    [theme.breakpoints.down("md")]: {
      width: "100%",
      height: 250,
      borderTopRightRadius: 8,
      borderTopLeftRadius: 8,
    },
  });

  const rightContentStyle = (theme: Theme) => ({
    p: 6,
    backgroundColor: "#B52129",
    textAlign: "center",
    [theme.breakpoints.down("md")]: {
      borderBottomRightRadius: 8,
      borderBottomLeftRadius: 8,
    },
  });

  const titleStyle = (theme: Theme) => ({
    fontWeight: 500,
    fontSize: 25,
    mb: 3,
  });

  const descStyle = (theme: Theme) => ({
    fontWeight: 400,
    fontSize: 14,
    lineHeight: 1.7,
    width: "60%",
    m: "auto",
    mb: 4,
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  });

  const buttonStyle = (theme: Theme) => ({
    backgroundColor: "#FFFFFF",
    display: "block",
    width: "20%",
    m: "auto",
    "&:hover": {
      backgroundColor: "#FFFFFF",
    },
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  });

  return (
    <Box component="section" sx={containerStyle}>
      <Box sx={contentStyle}>
        <Box sx={leftContentStyle} />
        <Box sx={rightContentStyle}>
          <Typography variant="h3" sx={titleStyle}>
            {t("cta:trialPackage.title")}
          </Typography>
          <Typography sx={descStyle}>
            {t("cta:trialPackage.description")}
          </Typography>

          <Link href={TRIAL_PRODUCT_PAGE_LINK}>
            <Button variant="outlined" size="large" sx={buttonStyle}>
              {t("cta:trialPackage.button")}
            </Button>
          </Link>
        </Box>
      </Box>
    </Box>
  );
};
