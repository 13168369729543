import { Box, Typography, useMediaQuery } from "@mui/material";
import { SxProps, useTheme } from "@mui/material/styles";
import { useRouter } from "next/router";
import React, { FC } from "react";

type Props = {
  children: React.ReactNode;
  backgroundColor: string;
  color: "primary" | "secondary";
  icon?: JSX.Element;
  title: string;
  description?: string;
  button: {
    label: string;
    href: string;
    startIcon?: JSX.Element;
  };
  containerStyles?: SxProps;
  actionStyles?: SxProps;
};

export const CTASingleSectionV2: FC<Props> = ({
  children,
  backgroundColor,
  color,
  icon,
  title,
  description,
  button,
  containerStyles,
  actionStyles,
}) => {
  const theme = useTheme();
  const router = useRouter();
  const isSm = useMediaQuery(theme.breakpoints.down("sm"));

  const handleButtonClick = (href: string) => {
    router.push(href);
  };
  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: {
          md: "center",
          lg: "center",
        },
        padding: {
          xs: theme.spacing(5, 4),
          sm: theme.spacing(5, 4),
          md: theme.spacing(5, 18),
          lg: theme.spacing(5, 18),
        },
        gap: 3,
        isolation: "isolate",
        position: "relative",
        overflow: "hidden",
        borderRadius: {
          xs: 1,
          sm: 1,
          md: 0,
          lg: 0,
        },
        backgroundColor,
        ...containerStyles,
        [theme.breakpoints.down("sm")]: {
          width: `calc(100% - ${theme.spacing(3)} - ${theme.spacing(3)})`,
          margin: theme.spacing(0, "auto"),
          height: "228px",
        },
      }}
    >
      {children}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: 2,
          color:
            color === "primary"
              ? theme.palette.primary.main
              : theme.palette.secondary.main,
          textAlign: {
            xs: "center",
            sm: "center",
          },
        }}
      >
        <Typography component="h3" variant={isSm ? "mobileH3" : "desktopH3"}>
          {title}
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "flex-start",
          paddingTop: 2,
          gap: 2,
          ...actionStyles,
        }}
      >
        {description && (
          <Box gap={1.75} display="inline-flex" alignItems="center">
            {!!icon && icon}
            <Typography component="p" variant={isSm ? "mobileH3" : "desktopH5"}>
              {description}
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
};
