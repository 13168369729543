import { BeijeDot04, BeijeDot07 } from "@beije/react-icons";
import { Box, Button, Typography, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useRouter } from "next/router";
import { useTranslation } from "next-i18next";
import { FC } from "react";

export const CTAWideDefault: FC = () => {
  const router = useRouter();
  const { t } = useTranslation();

  const theme = useTheme();
  const mediumBreakpoint = useMediaQuery(theme.breakpoints.up("md"));

  const handleButtonClick = (href: string) => {
    router.push(href);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: {
          xs: "column",
          sm: "column",
        },
        justifyContent: {
          xs: "center",
          sm: "center",
        },
        alignItems: {
          xs: "center",
          sm: "center",
        },
        padding: {
          xs: 2.5,
          sm: 2.5,
          md: 0,
          lg: 0,
        },
        gap: {
          xs: 3,
          sm: 3,
          md: 0,
          lg: 0,
        },
        backgroundColor: theme.palette.background.default,
        borderRadius: 1,
        width: "100%",
      }}
    >
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: {
            xs: "column",
            sm: "column",
            md: "row",
            lg: "row",
          },
          justifyContent: {
            xs: "center",
            sm: "center",
            md: "space-between",
            lg: "space-between",
          },
          alignItems: "center",
          gap: 8,

          padding: {
            xs: 2.5,
            sm: 2.5,
            md: 4,
            lg: 4,
          },
          borderRadius: 1,
          isolation: "isolate",
          position: "relative",
          overflow: "hidden",
          backgroundColor: theme.palette.common.beije.secondary.yellow.main,
        }}
      >
        <BeijeDot04
          style={{
            position: "absolute",
            left: mediumBreakpoint ? "calc(50% - 224px/2 + 380px)" : 109,
            top: mediumBreakpoint ? -26 : 23,
            opacity: mediumBreakpoint ? 0.1 : 0.2,
            width: 224,
            height: 222,
            zIndex: -1,
          }}
        />
        <BeijeDot07
          style={{
            position: "absolute",
            left: mediumBreakpoint ? "calc(50% - 109.37px/2 + 186.69px)" : -27,
            top: mediumBreakpoint ? -73 : -24,
            opacity: mediumBreakpoint ? 0.1 : 0.2,
            width: 109.37,
            height: 105.51,
            zIndex: -1,
          }}
        />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: 2,
            textAlign: {
              xs: "center",
              sm: "center",
              md: "left",
              lg: "left",
            },
          }}
        >
          <Typography
            component="h3"
            variant={mediumBreakpoint ? "desktopH3" : "mobileH3"}
          >
            {t("cta:wideDefault.title")}
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: {
              xs: "row",
              sm: "row",
            },
            justifyContent: {
              xs: "center",
              sm: "center",
            },
            alignItems: {
              xs: "flex-start",
              sm: "flex-start",
            },
            gap: {
              xs: 2,
              sm: 2,
            },
          }}
        >
          <Button
            size="large"
            variant="contained"
            onClick={() => handleButtonClick("/packets")}
          >
            {t("cta:wideDefault.button")}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};
