import { Box, Button, Typography } from "@mui/material";
import { SxProps, useTheme } from "@mui/material/styles";
import Link from "next/link";
import React, { FC } from "react";

type Props = {
  children: React.ReactNode;
  backgroundColor: string;
  color: "primary" | "secondary";
  icon?: JSX.Element;
  title: string;
  description?: string;
  button: {
    label: string;
    href: string;
    startIcon?: JSX.Element;
  };
  containerStyles?: SxProps;
  actionStyles?: SxProps;
};

export const CTASingleSection: FC<Props> = ({
  children,
  backgroundColor,
  color,
  icon,
  title,
  description,
  button,
  containerStyles,
  actionStyles,
}) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: {
          md: "center",
          lg: "center",
        },
        padding: {
          xs: theme.spacing(5, 4),
          sm: theme.spacing(5, 4),
          md: theme.spacing(5, 18),
          lg: theme.spacing(8, 18),
        },
        gap: 3,
        isolation: "isolate",
        position: "relative",
        overflow: "hidden",
        borderRadius: {
          xs: 1,
          sm: 1,
          md: 0,
          lg: 0,
        },
        backgroundColor,
        ...containerStyles,
      }}
    >
      {children}
      {icon && icon}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: 2,
          color:
            color === "primary"
              ? theme.palette.primary.main
              : theme.palette.secondary.main,
          textAlign: {
            xs: "center",
            sm: "center",
          },
        }}
      >
        <Typography component="h3" variant="mobileH3">
          {title}
        </Typography>
        {description && (
          <Typography
            component="p"
            variant="desktopBody2"
            sx={{ minHeight: "120px" }}
          >
            {description}
          </Typography>
        )}
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "flex-start",
          paddingTop: 2,
          paddingBottom: 3,
          gap: 2,
          ...actionStyles,
        }}
      >
        <Link href={button.href}>
          <Button
            startIcon={button && button.startIcon ? button.startIcon : null}
            size="large"
            variant="contained"
            color={color}
          >
            {button.label}
          </Button>
        </Link>
      </Box>
    </Box>
  );
};
