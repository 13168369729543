import { SVGProps, memo } from "react";

const SvgBeijeDot04 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    viewBox="0 0 142 141"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M133.032 41.946c5.71 11.35 8.78 21.43 7.87 33.35-.93 12.15-6 21.57-12.92 31.79-6.66 9.83-9.98 24.82-21.36 30.54-11.24 5.65-25.09.16-36.83-.54-12.09-.72-22.98-2.5-33.11-9.14-9.84-6.44-36.74-48.63-36.1-60.48.66-12.18 7.42-24.4 13.96-34.64 6.39-10 16.54-17.38 27.81-23.05 11.43-5.75 23.56-10.25 35.52-9.6 12.35.68 21.5 10.57 31.83 17.17 10.17 6.5 17.63 13.24 23.34 24.59l-.01.01Z"
      fill={props.fill || "#fff"}
    />
  </svg>
);

const Memo = memo(SvgBeijeDot04);
export default Memo;
