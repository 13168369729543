import { SVGProps, memo } from "react";

const SvgBeijeDot01 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    viewBox="0 0 141 142"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M131.808 113.456c-7.2 10.66-15.9 17.47-26.89 22.56-11.19 5.19-22.07 5.99-34.52 4.93-11.98-1.02-26.32 2.15-37.01-5.07-10.55-7.13-13.41-21.19-18.6-31.91-5.35-11.04-14.67-22.11-13.82-34.35.82-11.88 4.38-26.24 11.51-36.79s20-17.56 30.78-22.86c11.08-5.45 24.55-10.61 36.82-9.9 11.99.69 26.12 5.2 36.71 12.36 10.73 7.25 7.6 18.89 12.94 29.77 5.52 11.25 11.65 25.17 10.93 37.57-.7 12.2-1.64 23.03-8.84 33.68l-.01.01Z"
      fill={props.fill || "#fff"}
    />
  </svg>
);

const Memo = memo(SvgBeijeDot01);
export default Memo;
