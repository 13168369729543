import { SVGProps, memo } from "react";

const SvgBeijeDot07 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    viewBox="0 0 110 106"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M31.82 5.912c12.42-8.88 27.07-5.63 40.32-2.32 11.58 2.9 21.13 11.92 28.621 23.74 7.49 11.82 9.67 22.57 8.4 35.94-1.45 15.3-11.99 23.9-23.82 33.55-11.83 9.65-16.88 10.39-30.12 7.08-11.58-2.9-18.46.54-30.15-7.11-13.99-9.16-26.12-29.38-24.85-42.76 1.45-15.3 13.73-35.34 31.6-48.12Z"
      fill={props.fill || "#fff"}
    />
  </svg>
);

const Memo = memo(SvgBeijeDot07);
export default Memo;
