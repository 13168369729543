import { Box, Typography, Button } from "@mui/material";
import { Theme } from "@mui/material/styles";
import Link from "next/link";

import CTABlogImage from "public/images/cta-homepage-blog.jpg";
import { useTranslation } from "react-i18next";

export const CTABlog = () => {
  const { t } = useTranslation();

  const descriptionLines = t("cta:blog.description", {
    returnObjects: true,
  }) as string[];

  const containerStyle = (theme: Theme) => ({
    width: "100%",
    background: `linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), linear-gradient(270.13deg, rgba(0, 0, 0, 0.2) 0.11%, rgba(0, 0, 0, 0) 100.8%), url(${CTABlogImage.src})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    py: 18,
    px: 12,
    minHeight: 400,
    display: "grid",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      p: 4,
      borderRadius: 1,
      mb: 4,
      mx: "auto",
      width: "92%",
    },
  });

  const contentStyle = (theme: Theme) => ({
    display: "flex",
    color: "#FFFFFF",
    "& > div:nth-child(1)": {
      width: "70%",
    },
    "& > div:nth-child(2)": {
      maxWidth: "450px",
    },
    [theme.breakpoints.down("md")]: {
      alignItems: "center",
      flexDirection: "column",
      textAlign: "center",
      "& > div": {
        width: "80% !important",
      },
    },
  });

  const titleStyle = (theme: Theme) => ({
    fontWeight: 500,
    fontSize: 32,

    [theme.breakpoints.down("md")]: {
      mb: 4,
    },
  });

  const descStyle = (theme: Theme) => ({
    fontWeight: 400,
    fontSize: 14,
    lineHeight: 1.7,
  });

  const buttonStyle = (theme: Theme) => ({
    backgroundColor: "#FFFFFF",
    border: 1,
    borderColor: "#FFFFFF",
    "&:hover": {
      backgroundColor: "#FFFFFF",
      borderColor: "#000000",
    },
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  });

  return (
    <Box component="section" sx={containerStyle}>
      <Box sx={contentStyle}>
        <Box>
          <Typography variant="h3" sx={titleStyle}>
            {t("cta:blog.title")}
          </Typography>
        </Box>
        <Box>
          <Box mb={4}>
            {descriptionLines.map((item: string, index: number) => (
              <Typography key={index} sx={descStyle} mb={2}>
                {item}
              </Typography>
            ))}
          </Box>

          <Link href="https://dongu.beije.co" target="_blank">
            <Button variant="outlined" sx={buttonStyle}>
              {t("cta:blog.button")}
            </Button>
          </Link>
        </Box>
      </Box>
    </Box>
  );
};
