import { BeijeDot01, BeijeDot06, Mail01 } from "@beije/react-icons";
import { Box, useMediaQuery } from "@mui/material";
import { useTheme, Theme } from "@mui/material/styles";
import { FC } from "react";

import { CTASingleSectionV2 } from "./CTASingleSectionV2";

type CTAWideProps = {
  text: string;
};

export const CTAWide: FC<CTAWideProps> = ({ text }) => {
  const theme = useTheme();
  const mediumBreakpoint = useMediaQuery(theme.breakpoints.up("md"));

  const containerStyle = (tm: Theme) => ({
    [tm.breakpoints.down("sm")]: {
      width: "100%",
      height: "100%",
      backgroundColor: "white",
      py: 2.5,
    },
  });

  return (
    <Box sx={containerStyle}>
      <CTASingleSectionV2
        containerStyles={{ height: 260 }}
        backgroundColor={theme.palette.common.beije.secondary.lime.main}
        title={text}
        description="hersey@beije.co"
        icon={<Mail01 strokeWidth={1} />}
        button={{ href: "/", label: "" }}
        color="primary"
      >
        <BeijeDot01
          style={{
            position: "absolute",
            width: 279,
            height: 281,
            left: mediumBreakpoint ? 550 : 102,
            top: mediumBreakpoint ? 179 : 138,
            opacity: 0.1,
          }}
        />
        <BeijeDot06
          style={{
            position: "absolute",
            width: 210,
            height: 216,
            left: -57,
            top: -24,
            opacity: 0.1,
          }}
          fill="#000"
        />
      </CTASingleSectionV2>
    </Box>
  );
};
